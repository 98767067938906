import React from "react";
import Timeline from "../components/Timeline";
import { useTranslation } from 'react-i18next';

function Curriculum() {
  const { t } = useTranslation();

  const educationData = [
    {
      year: "2023-2025",
      title: t("title_university"), 
      description: t("university_software_engineering"),
    },
    {
      year: "2020-2023",
      title: t("title_university"),
      description: t("university_technical_degree"),
    },
  ];

  const experienceData = [
    {
      year: t("timeData"),
      title: "Optimen",
      description: t("optimen_experience"),
    },
  ];

  return (
    <div>
      <div className="relative flex flex-col items-center pt-20 text-black text-[28px] md:text-[40px] font-normal font-['Jomolhari']">
        <div>{t("cv")}</div>
      </div>
      <div className="min-h-screen flex flex-col justify-center items-center p-6 md:pr-60 pr-6">
        <div className="flex flex-col md:flex-row justify-between w-full md:w-3/4">
          <Timeline items={educationData} title={t("education")} /> 
          <Timeline items={experienceData} title={t("experience")} />
        </div>
      </div>
    </div>
  );
}

export default Curriculum;