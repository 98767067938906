import React from "react";
import Typewriter from "typewriter-effect";

function TypeSkills() {
  return (
    <Typewriter
      options={{
        strings: [
          "Trabajo en equipo.",
          "Pensamiento Creativo.",
          "Adaptabilidad.",
          "Comunicación."
        ],
        autoStart: true, 
        loop: true,
        deleteSpeed: 30,
      }}
    />
  );
}

export default TypeSkills;
