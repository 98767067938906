import React from "react";
import Type from "../components/Type";
import { FaInstagram } from "react-icons/fa6";
import { FaGithub, FaLinkedin } from "react-icons/fa";
import { useTranslation } from 'react-i18next';

function Home() {
  const { t } = useTranslation(); // Hook para acceder a las traducciones

  return (
    <>
      <div className="relative flex flex-col items-start text-black text-[28px] md:text-[40px] font-normal font-['Jomolhari'] px-5 md:px-16 py-20 md:py-40 space-y-2 md:space-y-4">
        <div>{t('hello')}</div> {/* Traducción para "Hola" */}
        <div>{t('name')}</div>  {/* Traducción para "Soy Abraham S Espinoza" */}
        <div>
          <span>{t('by')}: <Type /></span> {/* Traducción para "Por" o "By" */}
        </div>
        <div>{t('role')}</div> {/* Traducción para "Desarrollador de Software Jr" */}
        <div className="flex space-x-8 mt-10">
         <a className="text-black" href="https://www.linkedin.com/in/abrahames/" aria-label="Linkedin" target="_blank" rel="noreferrer"> 
           <FaLinkedin size={35} />
         </a> 
         <a className="text-black" href="https://github.com/Abrahames0" aria-label="Github" target="_blank" rel="noreferrer">
           <FaGithub size={35} />
         </a>
         <a className="text-black" href="https://www.instagram.com/abrahames0/" aria-label="Instagram" target="_blank" rel="noreferrer">
           <FaInstagram size={35} />
         </a> 
        </div>
      </div>
    </>
  );
}

export default Home;