import React from "react";

import aws from "../asset/img/icons-webp/amazon-web-services.webp";
import vsCode from "../asset/img/icons-webp/estudio-visual.webp";
import figma from "../asset/img/icons-webp/figma.webp";
import gitHub from "../asset/img/icons-webp/github.webp";
import js from '../asset/img/icons-webp/javascript.webp';
import materialUl from "../asset/img/icons-webp/material-ui.webp";
import nodejs from "../asset/img/icons-webp/nodejs.webp";
import reactjs from "../asset/img/icons-webp/react.webp";
import reactNative from "../asset/img/icons-webp/react-native.webp";
import tailwind from "../asset/img/icons-webp/tailwind-css.webp";
import mongo from "../asset/img/icons-webp/mongodb.webp";
import mysql from "../asset/img/icons-webp/mysql.webp";
import bootstrap from "../asset/img/icons-webp/boostrap.webp";
import java from "../asset/img/icons-webp/java.webp";
import python from "../asset/img/icons-webp/python.webp";
import firebase from '../asset/img/icons-webp/firebase.webp';
import { useTranslation } from "react-i18next";

const icons = {
  "React.js": reactjs,
  "React-native": reactNative, 
  "Node.js": nodejs,
  "JavaScript": js,
  "Python": python,
  "Java": java,
  "MongoDB": mongo,
  "MySql": mysql,
  "AWS": aws,
  "Firebase": firebase,
  "GitHub": gitHub,
  "Tailwind-css": tailwind,
  "VsCode": vsCode,
  "Material-UI": materialUl,
  "Bootstrap": bootstrap,
  "Figma": figma
};

function Skills() {
  const { t } = useTranslation();

  return (
    <div>
      <div className="relative flex flex-col items-center text-black text-[28px] md:text-[40px] font-normal font-['Jomolhari']">
        <div>{t("skills")}</div>
      </div>
      <div className="text-center text-lg mt-4 justify-center items-center">
        {Object.entries(icons).map(([name, icon], index) => (
          <div key={index} title={name} className="inline-block mx-4 group">
            <div className="relative w-20 h-20 rounded-full border-2 border-black overflow-hidden mt-3">
              <img src={icon} alt={name} className="absolute inset-0 w-12 h-12 mx-auto my-auto object-cover rounded-full grayscale transition duration-300 ease-in-out group-hover:grayscale-0"/>
            </div>
            <div className="text-center justify-center group-hover:text-gray-800 transition duration-300 ease-in-out">{name}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Skills;