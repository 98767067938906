import React from "react";

const TimelineItem = ({ year, title, description }) => {
  return (
     <div className="mb-8 flex flex-col md:flex-row justify-between items-center w-full relative z-10">
      <div className="order-1 md:w-5/12"></div>
      <div className="z-20 flex items-center order-1 bg-black shadow-xl w-5 h-5 rounded-full"></div>
      <div className="order-1 bg-gray-300 rounded-lg shadow-xl w-full md:w-5/12 px-6 py-4 mt-6 md:mt-0">
        <h3 className="mb-1 font-bold text-lg">{title}</h3>
        <span className="text-sm font-semibold text-gray-600">{year}</span>
        <p className="mt-2 text-gray-700">{description}</p>
      </div>
    </div>
  );
};

export default TimelineItem;
