// App.js
import React from 'react';
import Home from './pages/Home';
import Who from './pages/Who';
import Curriculum from './pages/Curriculum';
import Projects from './pages/Projects';
import Contact from './pages/Contact';
import Particle from './components/Particle';
import ButtonScroll from './components/ButtonScroll';

function App() {
  return (
    <div  >
      <section>
        <Particle />
      </section>

      <section>
        <ButtonScroll />
      </section>

      <section id="home">
        <Home />
      </section>
      
      <section id="who-is-salva">
        <Who />
      </section>
      
      <section id="curriculum">
        <Curriculum />
      </section>
      
      <section id="projects">
        <Projects />
      </section>
      
      <section id="contact">
        <Contact />
      </section>
    </div>
  );
}

export default App;
