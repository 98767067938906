import React from "react";
import TypeSkills from "../components/TypeSkills";
import img from "../asset/img/Abrahames-perfil.png";
import Skills from "../components/Skills";
import { useTranslation } from "react-i18next";
import TypeSkillsEn from "../components/TypeSkillsEn";

function Who() {
  const { t } = useTranslation();

  const type = t("who") === "Quien es Abrahames?" ? <TypeSkills /> : <TypeSkillsEn />;

  return (
      <div>
        <div className="flex flex-col md:flex-row items-start justify-between w-full px-11 md:px-16 pt-10">
          <div className="flex-shrink-0 px-16 z-20">
            <img src={img} alt="Abrahames" className="rounded-2xl w-52 h-52 md:w-64 md:h-64 z-50"/>
          </div>
          <div className="text-center md:text-left max-w-xl md:ml-auto mt-10 md:mt-0">
            <div className="text-black text-[28px] md:text-[40px] font-normal font-['Jomolhari']">
              {t("who")}
            </div>
            <div className="text-[20px] md:text-[28px] font-['Jomolhari']">
              {type}
            </div>
            <div align="justify" className="text-black text-[16px] md:text-[18px] font-normal font-['Jomolhari'] mt-4 md:mt-8">
              <span>
                {t("who_description")}
              </span>
            </div>
          </div>
        </div>
        <div className="mt-8">
          <Skills/>
        </div>
    </div>
  );
}

export default Who;