import React from "react";
import Typewriter from "typewriter-effect";

function TypeSkillsEn() {
  return (
    <Typewriter
      options={{
        strings: [
          "Teamwork.",
          "Creative Thinking.",
          "Adaptability.",
          "Communication."
        ],
        autoStart: true, 
        loop: true,
        deleteSpeed: 30,
      }}
    />
  );
}

export default TypeSkillsEn;
