import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import es from "../asset/img/icons-webp/espanol.webp";
import en from '../asset/img/icons-webp/Ingles.webp';
import { useTranslation } from 'react-i18next';

const NavItem = ({ href, children, isActive }) => (
  <Nav.Link
    href={href}
    className={`transition-colors duration-500 ease-in-out ${isActive ? 'text-[#6441a5] font-bold rounded-full shadow-md shadow-[#6441a5]' : 'text-black'}`}
    onClick={(e) => {
      e.preventDefault();
      const target = document.querySelector(href);
      if (target) {
        target.scrollIntoView({ behavior: 'smooth' });
      }
    }}
  >
    {children}
  </Nav.Link>
);

function NavbarCustom() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [activeSection, setActiveSection] = useState('home');
  const [navbarOpacity, setNavbarOpacity] = useState(1);
  const [scrollTimeout, setScrollTimeout] = useState(null);

  const changeLanguage = (lng) => {
    if (i18n && typeof i18n.changeLanguage === 'function') {
      i18n.changeLanguage(lng);
    } else {
      console.error('i18n no está correctamente inicializado o changeLanguage no es una función.');
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const maxScroll = 300;
      const newOpacity = Math.min(1, scrollY / maxScroll);

      setNavbarOpacity(newOpacity);

      if (scrollTimeout) clearTimeout(scrollTimeout);
      const timeout = setTimeout(() => {
        setNavbarOpacity(0);
      }, 15000);
      setScrollTimeout(timeout);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      if (scrollTimeout) clearTimeout(scrollTimeout);
    };
  }, [scrollTimeout]);

  useEffect(() => {
    const sections = document.querySelectorAll('section');
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: [0.4, 0.6] // Ajustamos el umbral para una mejor detección
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target.id);
        }
      });
    }, observerOptions);

    sections.forEach((section) => {
      observer.observe(section);
    });

    return () => {
      sections.forEach((section) => {
        observer.unobserve(section);
      });
    };
  }, []);

  return (
    <div className="fixed bottom-0 w-full z-50 pb-16 md:pb-20 text-black">
      <Navbar expand="lg" className={`bg-neutral-500 text-black p-2 max-w-80 md:max-w-2xl mx-auto rounded-3xl`} style={{ backgroundColor: `rgba(213, 221, 237, ${navbarOpacity})`, transition: 'background-color 0.3s' }}>
        <Container>
          <Navbar.Brand>
            <NavItem href="#home">{t("home")}</NavItem>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <NavItem href="#who-is-salva" isActive={activeSection === 'who-is-salva'}>{t("whoo")}</NavItem>
              <NavItem href="#curriculum" isActive={activeSection === 'curriculum'}>CV</NavItem>
              <NavItem href="#projects" isActive={activeSection === 'projects'}>{t("projects")}</NavItem>
              <NavItem href="#contact" isActive={activeSection === 'contact'}>{t("contact")}</NavItem>
            </Nav>
            <div className="pl-2 flex space-x-2">
              {i18n.language === 'es' ? (
                <button onClick={() => changeLanguage('en')} title={t("enido")} className="rounded-full shrink-0 border bg-neutral-200 border-solid border-white px-2 py-2 flex items-center space-x-2">
                  <img src={en} alt="Bandera Inglesa" className="h-6 w-6" />
                </button>
              ) : (
                <button onClick={() => changeLanguage('es')} title={t("esido")} className="rounded-full shrink-0 border bg-neutral-200 border-solid border-white px-2 py-2 flex items-center space-x-2">
                  <img src={es} alt="Bandera Española" className="h-6 w-6" />
                </button>
              )}
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default NavbarCustom;