import React from "react";
import { useTranslation } from "react-i18next";

function Footer() {
  const {t} = useTranslation();

  return (
    <div>
        <footer className="bg-white rounded-lg shadow m-1 dark:bg-gray-800">
        <div className="w-full mx-auto max-w-screen-xl p-4 md:flex md:items-center md:justify-center">
            <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">© {new Date().getFullYear()} {t("rights")}</span>
        </div>
        </footer>
    </div>
  );
}

export default Footer; 