import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FaArrowCircleUp } from "react-icons/fa";

function ButtonScroll() {
  const [showScrollButton, setShowScrollButton] = useState(false);
  const { t } = useTranslation();

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;

      if (scrollY > 400) {
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      {showScrollButton && (
        <button onClick={scrollToTop} title={t("go-home")} className=" z-50 fixed md:bottom-7 bottom-2 md:right-6 right-4 bg-[#6441a5] text-white p-3 rounded-full shadow-lg hover:bg-[#50338a] transition duration-400">
            <FaArrowCircleUp size={20}/>
        </button>
      )}
    </div>
  );
}

export default ButtonScroll;