import React from "react";
import { useTranslation } from "react-i18next";
import { FaGithub, FaLink } from "react-icons/fa";

const ProjectCard = ({ title, image, tech, reverse, description, git, url }) => {
  const { t } = useTranslation();

  return (
    <div className={`bg-gray-200 p-10 md:p-10 rounded-xl shadow-lg flex items-center justify-between w-full md:max-w-5xl ${reverse ? "flex-row-reverse" : "flex-row"} flex-col md:flex-row`}>
      <div className="flex-shrink-0 mb-6 md:mb-0 md:mr-6 md:w-1/3">
      <img src={image} alt={title} className="hidden md:block w-full h-auto object-contain rounded-xl mx-auto md:mx-0"/>
      <div className="flex flex-col md:flex-row justify-center md:space-x-10 space-y-4 md:space-y-0 mt-9 md:pr-1 pr-4">
        {git && (
            <a className="text-black" title="GitHub" aria-label="GitHub" target="_blank" rel="noreferrer" href={git}>
            <FaGithub size={22} />
            </a>
        )}
        {url && (
            <a className="text-black" title="Url" aria-label="Url" target="_blank" rel="noreferrer" href={url}>
            <FaLink size={22} />
            </a>
        )}
        </div>
      </div>
      <div className="flex-1 flex flex-col items-center justify-between">
        <div className="text-center mb-4 md:mb-9 ">
          <h3 className="text-lg md:text-2xl font-bold mb-4">{title}</h3>
          <h6 className="text-justify pr-4">{description}</h6>
        </div>
        <div className="flex flex-col items-center">
          <p className="text-sm md:text-base mb-2">{t("tech used")}</p>
          <div className="flex items-center space-x-2">
            {tech.map((t, index) => (
              <img key={index} src={t} alt={`tech-${index}`} className="w-6 h-6 md:w-8 md:h-8"/>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;
