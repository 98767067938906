import React from "react";
import ProjectCard from "../components/ProjectCard";
import JS from "../asset/img/icons-webp/javascript.webp";
import ReactIcon from "../asset/img/icons-webp/react.webp";
import AWS from "../asset/img/icons-webp/amazon-web-services.webp";
import python from "../asset/img/icons-webp/python.webp";
import java from "../asset/img/icons-webp/java.webp";
import sql from "../asset/img/icons-webp/mysql.webp";
import boos from "../asset/img/icons-webp/boostrap.webp";
import material from "../asset/img/icons-webp/material-ui.webp";
import tailwind from "../asset/img/icons-webp/tailwind-css.webp";

import Color from "../asset/img/Logo color de tu esencia.png";
import wlstore from "../asset/img/wlstore.png";
import universidad from '../asset/img/Universidad-Nova.png';
import gitImg from "../asset/img/git.png";
import { useTranslation } from "react-i18next";

function Projects() {
  const { t } = useTranslation();

  const projects = [
    {
      title: 'Color de tu esencia 🖍️',
      description: t("descriptionColor"),
      image: Color,
      url: 'https://main.drscmnhjego5l.amplifyapp.com/',
      git: 'https://github.com/Abrahames0/Color-de-Tu-Esencia', 
      tech: [JS, python, ReactIcon, AWS, tailwind, material ],
    },
    {
      title: 'WL STORE 🛒',
      description: t("descriptionStore"),
      image: wlstore,
      url: 'https://prod.d20njex5crjgev.amplifyapp.com/',
      git: 'https://github.com/Abrahames0/WorkLinker-Arquitectura',
      tech: [JS, ReactIcon, AWS, boos, material],
    },
    {
      title: 'Universidad Nova 👨‍🎓',
      description: t("descriptionUni"),
      image: universidad,
      git: 'https://github.com/Abrahames0/Universidad-Nova',
      tech: [JS, ReactIcon, AWS, boos, material],
    },
    {
      title: 'Tiendita Spring 🏪',
      description: t("descriptionSprint"),
      image: gitImg,
      git: 'https://github.com/Abrahames0/store-by-spring-boot',
      tech: [JS, ReactIcon, java, sql, boos],
    },
  ];

  return (
    <div className="relative flex flex-col items-center pt-28">
      <div className="text-black text-[28px] md:text-[40px] font-normal font-['Jomolhari'] mb-4">
        {t("projects")}
      </div>
      <div className="space-y-8">
        {projects.map((project, index) => (
          <ProjectCard  key={index} title={project.title} url={project.url} git={project.git} image={project.image} description={project.description} tech={project.tech} reverse={index % 2 !== 0} />
        ))}
      </div>
    </div>
  );
}

export default Projects;