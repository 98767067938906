import React from "react";
import { FaGraduationCap, FaBriefcase } from 'react-icons/fa';
import TimelineItem from "./TimelineItem";
import { useTranslation } from "react-i18next";

const Timeline = ({ items, title }) => {
  const { t } = useTranslation();

  const icon = title === t("education") ? <FaGraduationCap size={25} /> : <FaBriefcase size={25} />;
  
  return (
    <div className="w-full md:w-1/2 mb-10 md:mb-0 relative">
      <h2 className="flex items-center justify-center text-center text-2xl font-['Jomolhari'] font-normal mb-8">
        <span className="mr-2 bg-gray-200 p-2 rounded-full">{icon}</span>
        {title}
      </h2>
      <div className="relative">
        <div className="absolute left-1/2 w-1 h-full bg-black transform -translate-x-1/2 z-0"></div>
        {items.map((item, index) => (
          <TimelineItem key={index} year={item.year} title={item.title} description={item.description}/>
        ))}
      </div>
    </div>
  );
};

export default Timeline;