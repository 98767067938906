import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import App from './App';
import Particle from './components/Particle';
import NavbarCustom from './components/Navbars';
import './i18n';
import Footer from './components/Footer';

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <Suspense fallback={<div>Loading...</div>}>
      <NavbarCustom />
      <Particle />
      <App />
      <Footer/>
    </Suspense>
  </React.StrictMode>
);

reportWebVitals();