import React from "react";
import { FaGithub, FaInstagram, FaLinkedin } from "react-icons/fa6";
import { useTranslation } from 'react-i18next';

function Contact() {
  const { t } = useTranslation();

  return (
    <div className="relative flex flex-col items-center pt-40 ">
      <div className="text-black text-[28px] md:text-[40px] font-normal font-['Jomolhari']">
        {t('contact_me')}
      </div>
      <div className="flex justify-center space-x-10 md:space-x-40 mt-9">
        <a className="text-black hover:text-blue-600 transition-colors duration-300" title="Linkedin" href="https://www.linkedin.com/in/abrahames/" aria-label="Linkedin" target="_blank" rel="noreferrer">
          <FaLinkedin size={45}  />
        </a>
        <a className="text-black hover:text-gray-600 transition-colors duration-300" title="GitHub" href="https://github.com/Abrahames0" aria-label="Github" target="_blank" rel="noreferrer">
          <FaGithub size={45}  />
        </a>
        <a className="text-black hover:text-pink-600 transition-colors duration-300" title="Instagram" href="https://www.instagram.com/abrahames0/" aria-label="Instagram" target="_blank" rel="noreferrer">
          <FaInstagram size={45}  />
        </a>
      </div>
      <form
        action="https://formspree.io/f/mkgweywd" method="POST" className="mt-10 w-full max-w-96 md:max-w-lg">
        <div className="mb-4">
          <label htmlFor="email" className="block text-gray-700 text-sm font-bold mb-2">
            {t('email')}
          </label>
          <input type="email" name="email" id="email" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder={t('enter_email')} required/>
        </div>
        <div className="mb-4">
          <label htmlFor="name" className="block text-gray-700 text-sm font-bold mb-2">
            {t('name_contact')}
          </label>
          <input type="text" name="name" id="name" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder={t('enter_name')} required/>
        </div>
        <div className="mb-4">
          <label htmlFor="message" className="block text-gray-700 text-sm font-bold mb-2">
            {t('message')}
          </label>
          <textarea name="message" id="message" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder={t('enter_message')} rows="4" required/>
        </div>
        <div className="flex justify-center mb-32">
          <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline">
            {t('send_message')}
          </button>
        </div>
      </form>
    </div>
  );
}

export default Contact;
